.footer {
    padding: 86px 0 55px;

    text-align: center;
    background-color: #4B4846;
}

.footer__title {
    margin: 0 0 21px;

    font-size: 25px;
    font-weight: 700;
    line-height: 20px;
}

.footer__subtitle {
    margin: 0 0 56px;

    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
}

.footer__buttons {
    margin: 0 0 56px;
    padding: 33px 0;

    display: flex;
    justify-content: center;

    background: linear-gradient(90deg, #1ACFDA 0%, #1994D3 100%);
}

.footer__button {
    width: 230px;
    height: 75px;

    padding: 17px 0 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    border-radius: 8px;
    background-color: #fff;

    &:first-child {
        margin: 0 43px 0 0;
    }

    & img {
        margin: 0 25px 0 0;
    }
}

.footer_button__download {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(75, 72, 70, 1);
}

.footer_button__store {
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    color: rgba(75, 72, 70, 1);
}

.footer__links {
    & a {
        margin: 0 61px 0 0;

        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(142, 142, 147, 1);
        text-decoration: none;
    }

    & a:last-child {
        margin: 0;
    }
}
